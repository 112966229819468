<template>
 <div>
  <svg height="188.798" viewBox="0 0 188.798 188.798" width="188.798" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient2" x1="2.596" x2="2.728" y2="2.743">
     <stop offset="0" stop-color="#ffc000"/>
     <stop offset="1" stop-color="#ffc400"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-12" x1="0.649" x2="0.196" y2="1">
     <stop offset="0" stop-color="#ffc400"/>
     <stop offset="1" stop-color="#ffc400"/>
    </linearGradient>
   </defs>
   <g id="icon_logo_untappd" transform="translate(-1887 -1776.962)">
    <rect :fill="background" data-name="Rectangle 21" height="188.798" id="Rectangle_21" transform="translate(1887 1776.962)" width="188.798"/>
    <g id="untappd-vector-logo" transform="translate(1911 1819.971)">
     <g data-name="Group 456" id="Group_456" transform="translate(32.027 98.634)">
      <path d="M98.716,302.034v3.029h.851a1.421,1.421,0,0,0,1.514-1.494v-.021a1.437,1.437,0,0,0-1.514-1.514ZM97.7,301.1h1.888a2.435,2.435,0,0,1,2.593,2.427v.021A2.451,2.451,0,0,1,99.588,306H97.7Zm8.775,2.386c.539,0,.851-.29.851-.726v-.021c0-.477-.332-.726-.871-.726h-1.1v1.452h1.12Zm-2.137-2.386h2.22a1.855,1.855,0,0,1,1.411.5,1.5,1.5,0,0,1,.415,1.1v.021a1.523,1.523,0,0,1-1.058,1.514L108.549,306h-1.2l-1.079-1.6h-.913V306h-1.016Z" data-name="Path 559" fill="url(#linear-gradient2)" id="Path_559" transform="translate(-97.7 -301.017)"/>
      <path d="M159.9,305.975h1.037V301.1H159.9Z" data-name="Path 560" fill="url(#linear-gradient2)" id="Path_560" transform="translate(-146.997 -301.017)"/>
      <path d="M176.6,301.1h.934l2.365,3.07V301.1h1.016v4.875h-.892l-2.406-3.174v3.174H176.6Z" data-name="Path 561" fill="url(#linear-gradient2)" id="Path_561" transform="translate(-160.233 -301.017)"/>
      <path d="M208.9,301.1h1.016v2.2l2.033-2.2h1.265l-1.991,2.074,2.074,2.8h-1.224l-1.556-2.1-.6.622v1.473H208.9Z" data-name="Path 562" fill="url(#linear-gradient2)" id="Path_562" transform="translate(-185.832 -301.017)"/>
      <path d="M253.3,304.953l.6-.726a2.222,2.222,0,0,0,1.473.6c.456,0,.726-.187.726-.5v-.021c0-.29-.166-.436-1-.643-1-.249-1.618-.539-1.618-1.494v-.021a1.516,1.516,0,0,1,1.7-1.452,2.84,2.84,0,0,1,1.8.622l-.539.788a2.232,2.232,0,0,0-1.286-.5c-.436,0-.664.207-.664.456v.021c0,.332.207.456,1.079.664,1,.249,1.556.622,1.556,1.452v.021c0,.954-.726,1.494-1.784,1.494a2.95,2.95,0,0,1-2.054-.768m9.854-1.722h0a1.529,1.529,0,0,0-1.514-1.6,1.489,1.489,0,0,0-1.494,1.556v.021a1.524,1.524,0,0,0,1.514,1.577,1.478,1.478,0,0,0,1.494-1.556m-4.087,0h0a2.529,2.529,0,0,1,2.593-2.531,2.494,2.494,0,0,1,2.572,2.51v.021a2.583,2.583,0,0,1-5.165,0m7.095,0h0a2.464,2.464,0,0,1,2.531-2.531,2.584,2.584,0,0,1,1.929.747l-.664.768a1.782,1.782,0,0,0-1.265-.56,1.481,1.481,0,0,0-1.452,1.556v.021a1.5,1.5,0,0,0,1.452,1.577,1.823,1.823,0,0,0,1.307-.581l.664.664a2.472,2.472,0,0,1-1.991.871,2.5,2.5,0,0,1-2.51-2.531" data-name="Path 563" fill="url(#linear-gradient2)" id="Path_563" transform="translate(-221.022 -300.7)"/>
      <path d="M347.2,305.975h1.017V301.1H347.2Z" data-name="Path 564" fill="url(#linear-gradient2)" id="Path_564" transform="translate(-295.443 -301.017)"/>
      <path d="M364.978,303.8l-.685-1.66-.685,1.66Zm-1.162-2.9h.954l2.1,4.916h-1.079l-.456-1.12h-2.116l-.456,1.12H361.7Z" data-name="Path 565" fill="url(#linear-gradient2)" id="Path_565" transform="translate(-306.935 -300.859)"/>
      <path d="M396.1,301.1h1.037v3.941h2.448v.933H396.1Z" data-name="Path 566" fill="url(#linear-gradient2)" id="Path_566" transform="translate(-334.199 -301.017)"/>
      <path d="M422.6,301.1h1.037v3.941h2.448v.933H422.6Z" data-name="Path 567" fill="url(#linear-gradient2)" id="Path_567" transform="translate(-355.201 -301.017)"/>
      <path d="M444.188,304.066,442.3,301.1h1.2l1.2,1.991,1.224-1.991h1.182l-1.888,2.946v1.929h-1.037Z" data-name="Path 568" fill="url(#linear-gradient2)" id="Path_568" transform="translate(-370.815 -301.017)"/>
     </g>
     <g data-name="Group 457" id="Group_457" transform="translate(0 68.811)">
      <path d="M0,225.99v-14.6a.23.23,0,0,1,.228-.228H4.543a.23.23,0,0,1,.228.228V225.7c0,3.381,1.473,4.688,4.647,4.688,3.132,0,4.564-1.307,4.564-4.688V211.386a.23.23,0,0,1,.228-.228h4.315a.23.23,0,0,1,.228.228v14.6c0,5.871-4.066,8.713-9.335,8.713C4.066,234.682,0,231.861,0,225.99m22.259-15.081,14.148,13.318-.021-12.841a.23.23,0,0,1,.228-.228h4a.23.23,0,0,1,.228.228v23.047a.268.268,0,0,1-.456.187l-14.127-13.3v12.82a.23.23,0,0,1-.228.228h-4a.23.23,0,0,1-.228-.228l.021-23.047a.252.252,0,0,1,.436-.187M50.1,215.348H43.709a.23.23,0,0,1-.228-.228v-3.734a.23.23,0,0,1,.228-.228H61.238a.23.23,0,0,1,.228.228v3.734a.23.23,0,0,1-.228.228H54.848v18.794a.23.23,0,0,1-.228.228H50.326a.23.23,0,0,1-.228-.228V215.348Zm19.043,4.46-3.091,7.945h6.1Zm.249-8.775L79.8,234.06a.24.24,0,0,1-.207.332h-4.5a.206.206,0,0,1-.207-.145l-1.037-2.489H64.432L63.4,234.246a.25.25,0,0,1-.207.145H59.1a.227.227,0,0,1-.207-.332l10.02-23.026a.261.261,0,0,1,.477,0Zm19.085,13.857c3.879,0,5.58-1.577,5.58-4.75,0-3.215-1.763-4.792-5.58-4.792H86.981v9.542Zm-6.265-13.5a.23.23,0,0,1,.228-.228H88.62c6.472,0,10.372,3.381,10.372,8.982S95.072,229.1,88.62,229.1H86.981v5.062a.23.23,0,0,1-.228.228H82.438a.23.23,0,0,1-.228-.228Zm25.868,13.5c3.879,0,5.6-1.577,5.6-4.75,0-3.215-1.784-4.792-5.6-4.792h-1.494v9.542Zm-6.265-13.5a.23.23,0,0,1,.228-.228h6.182c6.472,0,10.372,3.381,10.372,8.982s-3.921,8.962-10.372,8.962h-1.639v5.062a.23.23,0,0,1-.228.228h-4.315a.23.23,0,0,1-.228-.228V211.386ZM127.89,230.18c5.145,0,7.51-3.07,7.51-7.406s-2.344-7.406-7.51-7.406h-2.116V230.2l2.116-.021ZM121,211.386a.23.23,0,0,1,.228-.228h6.887c7.136,0,12.2,4.75,12.2,11.617s-5.041,11.617-12.2,11.617h-6.887a.23.23,0,0,1-.228-.228Z" data-name="Path 569" fill="url(#linear-gradient2)" id="Path_569" transform="translate(0 -210.833)"/>
     </g>
     <g data-name="Group 458" id="Group_458" transform="translate(34.529 -0.009)">
      <path d="M138.074,33.578l-17.3,24.167a4.143,4.143,0,0,1-3.879,1.7,13.888,13.888,0,0,1-6.514-2.614,14.382,14.382,0,0,1-4.564-5.352,4.192,4.192,0,0,1,.353-4.232l17.3-24.167a9.373,9.373,0,0,1,3.153-2.759l3.4-1.8a6.634,6.634,0,0,0,1.8-1.411c1.66-1.867,6.078-6.783,13.961-14.729l.145-.622a.379.379,0,0,1,.29-.29l.436-.1a.385.385,0,0,0,.29-.415l-.042-.539a.371.371,0,0,1,.373-.415,5.038,5.038,0,0,1,2.966,1.162A5.019,5.019,0,0,1,152.3,3.6a.4.4,0,0,1-.29.5l-.519.124a.423.423,0,0,0-.311.415l.041.456a.4.4,0,0,1-.187.373l-.56.332c-4.979,10-8.215,15.787-9.459,17.965a6.21,6.21,0,0,0-.768,2.178l-.6,3.817A9.021,9.021,0,0,1,138.074,33.578Zm37.838,13.65-17.3-24.167a9.373,9.373,0,0,0-3.153-2.759l-3.4-1.8a6.634,6.634,0,0,1-1.8-1.411c-.415-.456-1-1.12-1.743-1.95a.255.255,0,0,0-.415.062c-2.614,5.041-4.4,8.173-5.186,9.584a4.572,4.572,0,0,0-.519,1.431,10.851,10.851,0,0,0,0,3.278l.021.187a9.242,9.242,0,0,0,1.6,3.879l17.3,24.167a4.1,4.1,0,0,0,3.838,1.7,14.092,14.092,0,0,0,6.555-2.614,14.382,14.382,0,0,0,4.564-5.352,4.144,4.144,0,0,0-.353-4.232M130.067,4.059l.519.124a.423.423,0,0,1,.311.415l-.041.456a.4.4,0,0,0,.187.373l.56.332c.913,1.846,1.784,3.547,2.572,5.1a.246.246,0,0,0,.394.062c1.307-1.39,2.863-3.049,4.709-4.958a.394.394,0,0,0,0-.56c-.933-.954-1.908-1.95-2.987-3.029l-.145-.622a.379.379,0,0,0-.29-.29l-.436-.1a.385.385,0,0,1-.29-.415l.041-.539a.371.371,0,0,0-.373-.415,4.976,4.976,0,0,0-2.987,1.162A5.019,5.019,0,0,0,129.756,3.6a.407.407,0,0,0,.311.456" data-name="Path 570" fill="url(#linear-gradient-12)" id="Path_570" transform="translate(-105.402 0.009)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>